<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <modal ref="bookingSuccessModal">
      <template v-slot:header>
        <center>
          <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_Vwcw5D.json"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px; margin-bottom: -85px"
            loop
            autoplay
          ></lottie-player>
        </center>
        <h1 class="pt-4 pb-2 font-semibold text-3xl text-center text-green-500">
          THANK YOU FOR YOUR BOOKING
        </h1>
        <p class="text-center text-gray-500">
          We've received your booking request. We cant wait to welcome you to
          the Maldives. We have sent you a confirmation email with further
          instructions.
        </p>
      </template>

      <template v-slot:body>
        <h1 class="pt-2 pb-12 text-2xl text-center text-green-700">
          Start packing your bags!
        </h1>
      </template>

      <template v-slot:footer>
        <div class="flex text-center items-center justify-center">
          <button
            class="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700 transition ease-in-out duration-150"
            @click="$refs.bookingSuccessModal.closeModal()"
          >
            CLOSE
          </button>
        </div>
      </template>
    </modal>

    <div class="flex md:flex-row mx-2 flex-wrap">
      <div class="w-full md:w-1/2 bg-white p-4 text-center text-gray-200">
        <h2
          class="text-2xl text-center text-green-600 tracking-wide font-semibold leading-loose mb-2"
        >
          Request Package
        </h2>

        <div
          v-if="bookingErrors.length > 0"
          class="text-red-500 text-left p-6 bg-red-200 border border-red-400"
        >
          <h3 class="text-xl font-bold">Errors in form.</h3>
          <small class="text-sm"
            >Please correct the below error(s) in the booking form.</small
          >
          <ul class="mt-4 list-disc ml-6">
            <li v-for="(e, i) in bookingErrors" :key="i">{{ e.error }}</li>
          </ul>
        </div>

        <div class="relative">
          <!-- Booking Form-->
          <div
            :class="{ hidden: formState !== 'accomodation' }"
            name="section1"
          >
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center justify-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <rect
                          x="3"
                          y="4"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      DATES
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-green-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>

                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-gray-500 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Select Dates Between
                  </div>
                  <div class="flex flex-col md:flex-row"></div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Check-in
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          @change="onArrivalDateChange"
                          v-model="booking.arrivalDate"
                          type="date"
                          ref="arrivalDateInput"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                      <small class="text-yellow-600"
                        >Arrival date in between
                        {{
                          moment(currentPackage.stayBetweenStart).format(
                            "DD-MM-YYYY"
                          )
                        }}
                        and
                        {{
                          moment(currentPackage.stayBetweenEnd).format(
                            "DD-MM-YYYY"
                          )
                        }}</small
                      >
                    </div>
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Check-out
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          disabled
                          v-model="booking.departureDate"
                          ref="departureDateInput"
                          type="date"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <div class="flex-auto flex flex-row-reverse">
                    <button
                      @click.prevent="formState = 'yourdetails'"
                      class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Break -->

          <div :class="{ hidden: formState !== 'yourdetails' }" name="section2">
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <rect
                          x="3"
                          y="4"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      DATES
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>

                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"
                  ></div>
                  <div class="flex items-center text-gray-500 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Full Name
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full flex-1 mx-2">
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="booking.name"
                          ref="nameInput"
                          placeholder="Your full name here"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Your Email
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          ref="emailInput"
                          v-model="booking.email"
                          type="email"
                          placeholder="jhon@doe.com"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Contact No.
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          ref="phoneInput"
                          v-model="booking.phone"
                          placeholder="Just a hint.."
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Nationality
                  </div>

                  <div class="flex flex-col md:flex-row">
                    <div class="w-full flex-1 mx-2">
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <input
                          v-model="booking.nationality"
                          ref="nameInput"
                          placeholder="Your Nationality"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="w-full mx-2 flex-1">
                      <div
                        class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                      >
                        Your Requirements
                      </div>
                      <div
                        class="bg-white my-2 p-1 flex border border-gray-200 rounded"
                      >
                        <textarea
                          v-model="booking.requirements"
                          type="text"
                          placeholder="Write us any additional information about your holiday. Tell us more about your wishes, budget or anything you would like us to know"
                          class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <button
                    @click.prevent="formState = 'accomodation'"
                    class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-200 bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
                  >
                    Previous
                  </button>
                  <div class="flex-auto flex flex-row-reverse">
                    <button
                      @click.prevent="formState = 'confirm'"
                      class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Break -->

          <!-- Addon Section Removed -->

          <!-- Break -->

          <div :class="{ hidden: formState !== 'confirm' }" name="section3">
            <div class="p-5">
              <div class="mx-4 p-4">
                <div class="flex items-center">
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-activity"
                      >
                        <rect
                          x="3"
                          y="4"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      DATES
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white-600 relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user-plus"
                      >
                        <path
                          d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="8.5" cy="7" r="4"></circle>
                        <line x1="20" y1="8" x2="20" y2="14"></line>
                        <line x1="23" y1="11" x2="17" y2="11"></line>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Your Details
                    </div>
                  </div>
                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>

                  <div
                    class="flex-auto border-t-2 transition duration-500 ease-in-out border-green-600"
                  ></div>
                  <div class="flex items-center text-white relative">
                    <div
                      class="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-green-600 border-green-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div
                      class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-green-600"
                    >
                      Confirm
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 p-4">
                <div>
                  <div
                    class="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mx-2 mt-3"
                  >
                    Confirmation Section
                  </div>
                </div>
                <div class="flex p-2 mt-4">
                  <button
                    @click.prevent="formState = 'yourdetails'"
                    class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-200 bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
                  >
                    Previous
                  </button>
                  <div class="flex-auto flex flex-row-reverse">
                    <button
                      @click="submitBooking()"
                      class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-blue-200 bg-blue-100 text-blue-700 border duration-200 ease-in-out border-blue-600 transition"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  Booking Form -->

          <div class="text-left mt-2 text-black">
            <span> </span>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 bg-gray-100 p-4 text-center text-gray-700">
        <h2
          class="text-2xl text-center text-black tracking-wide font-semibold leading-loose mb-2"
        >
          {{ currentPackage.type ? currentPackage.type.name : "" }} at
          {{ currentPackage.property ? currentPackage.property.name : "" }}
        </h2>
        <!-- PLACEHOLDER -->
        <room-card
          v-if="currentPackage.roomType"
          :displayOnly="true"
          :showAmenities="true"
          :property="currentPackage.property"
          :roomType="currentPackage.roomType"
        ></room-card>
      </div>
    </div>

    <div class="max-w-6xl mx-auto mt-6">
      <div class="flex flex-wrap">
        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3309285-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Luxury Maldives Experience
              </h2>
              <p class="text-sm">
                We can guarentee peace of mind when booking with us
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3533484-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Travel Insurance Protection
              </h2>
              <p class="text-sm">
                Our holiday packages are financially protected by Travel
                Insurance
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img
                  src="https://static.thenounproject.com/png/3188775-200.png"
                  width="75px"
                />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Flexible Payments
              </h2>
              <p class="text-sm">
                Spread the cost of your luxury holiday with monthly payments
                with easy online payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both; display: block; height: 0px"></div>

    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import Modal from "@/components/others/Modal";
//import SearchDropDown from "@/components/others/SearchDropDown";
// import SearchBar from "../others/SearchBar.vue";
import RoomCard from "@/components/others/RoomCard";
// import { createSlides } from "../others/slides.js";

export default {
  name: "BookingPage",
  data() {
    return {
      moment,
      formState: this.$route.query.formstate || "accomodation",
      bookingErrors: [],
      booking: {
        name: "",
        email: "",
        phone: "",
        nationality: "",
        arrivalDate: "",
        departureDate: "",
        requirements: "",
      },
      roomType: "",
      currentPackage: "",
      showModal: false,
    };
  },

  mounted() {
    this.getPackage();
  },
  // eslint-disable-next-line
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_BOOKING_FIELD", {
      field: "specialOffer",
      value: { name: "", percentage: "" },
    });
    next();
  },
  methods: {
    onArrivalDateChange(e) {
      if (
        moment(e.target.value).isBefore(this.currentPackage.stayBetweenStart)
      ) {
        this.booking.arrivalDate = null;
        return;
      }

      this.booking.departureDate = moment(e.target.value)
        .add(this.currentPackage.type.nights, "day")
        .format("YYYY-MM-DD");

      console.log(
        moment(e.target.value).add(this.currentPackage.type.nights, "day")
      );
    },
    async getPackage() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/packages/${this.$route.query.package}`
        );
        this.currentPackage = response.data.package;
        console.log(response.data.package);
      } catch (e) {
        console.log(e);
      }
    },
    validated() {},
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties?getAll=true`
        );
        this.properties = response.data.properties;
      } catch (e) {
        console.log(e);
      }
    },
    async submitBooking() {
      try {
        const packBooking = this.booking;

        packBooking.package = this.currentPackage._id;

        const response = await axios.post(
          `${this.$customConfig.api_host}/api/v1/packagebookings`,
          this.booking
        );
        if (response.status === 201) {
          this.$refs.bookingSuccessModal.openModal();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    HeaderLayout,
    FooterLayout,
    // SearchDropDown,
    Modal,
    RoomCard,
  },
};
</script>

<style scoped>
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
           WDM
 –––––––––––––––––––––––––––––––––––––––––––––––––– */

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

/*each image is referenced twice in the HTML, but each image only needs to be updated in the CSS*/
.image1 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e73d01476.jpg");
}

.image2 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e35541455.jpg");
}

.image3 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e5763146a.jpg");
}

.image4 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070eaec71491.jpg");
}

.image5 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070ebff014a0.jpg");
}

.transall {
  transition: all 0.5s;
}
</style>
